import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Flex, Box } from "@chakra-ui/core"
import firebase from "gatsby-plugin-firebase"
import Layout from "../components/Layout"
import { H2 } from "../components/Typography"
import { SquareAd, BannerAd } from "../components/Ad"
import FeaturedSlider from "../components/FeaturedSlider"
import SEO from "../components/SEO"
import { maxMobileSize } from "../constants"
import useWindowSize from "../hooks/useWindowSize"
import { ArticleCard } from "../templates/articleList"
import { NoticeCard } from "../templates/noticeList"

const wrapperStyle = {
  width: "100%",
  height: "100%",
  margin: "auto",
}

function getRandom(min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

function getRandomArr(arr, n) {
  if (arr.length < 1) return []
  let numItems = Math.min(n, arr.length)
  let result = new Array(numItems),
    len = arr.length,
    taken = new Array(len)
  if (numItems > len)
    throw new RangeError("getRandomArr: more elements taken than available")
  while (numItems--) {
    let x = Math.floor(Math.random() * len)
    result[numItems] = arr[x in taken ? taken[x] : x]
    taken[x] = --len in taken ? taken[len] : len
  }
  return result
}

export default function Home() {
  useEffect(() => {
    firebase.analytics().setCurrentScreen("home_page")
  }, [])

  const [articles, setArticles] = useState([])
  const [notices, setNotices] = useState([])
  const [bannerAds, setBannerAds] = useState([])
  const [squareAds, setSquareAds] = useState([])

  const data = useStaticQuery(graphql`
    query {
      allArticle(limit: 6, sort: { fields: fields___date, order: DESC }) {
        edges {
          node {
            id
            title
            summary
            articleCategories {
              name
            }
            featuredImage {
              childImageSharp {
                fixed(width: 250, height: 140) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            otherImagesLinks
            description
            fields {
              slug
              timeToRead
              date(formatString: "DD MMMM YYYY")
            }
          }
        }
      }
      allNotice(limit: 3, sort: { fields: fields___date, order: DESC }) {
        edges {
          node {
            id
            title
            noticeCategories {
              name
            }
            desktopImage: image {
              childImageSharp {
                fixed(width: 200, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            mobileImage: image {
              childImageSharp {
                fixed(width: 150, height: 160) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            description
            fields {
              date(formatString: "DD MMMM YYYY")
              slug
            }
          }
        }
      }
      allAd {
        edges {
          node {
            customer
            id
            type
            imageUrl
            website
          }
        }
      }
      facebook: file(relativePath: { eq: "facebook.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 260, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      twitter: file(relativePath: { eq: "twitter.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 260, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      instagram: file(relativePath: { eq: "instagram.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 260, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      whatsapp: file(relativePath: { eq: "whatsapp.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 260, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      logo: file(relativePath: { eq: "logo512.png" }) {
        childImageSharp {
          resize(width: 1200) {
            height
            src
            width
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (data.allArticle.edges.length > 0)
      setArticles(data.allArticle.edges.map(edge => edge.node))
    if (data.allNotice.edges.length > 0)
      setNotices(data.allNotice.edges.map(edge => edge.node))
    if (data.allAd.edges.length > 0) {
      const adsList = data.allAd.edges.map(edge => edge.node)
      const bannerAds = adsList.filter(ad => ad.type === "banner")
      const squareAds = adsList.filter(ad => ad.type === "square")
      setSquareAds(getRandomArr(squareAds, 3))
      setBannerAds(bannerAds)
    }
  }, [data])

  const windowSize = useWindowSize()
  const isDesktop = windowSize.width > maxMobileSize

  const image = data.logo.childImageSharp.resize || null

  return (
    <Layout noPadding>
      <SEO image={image} />
      <Flex mt={isDesktop ? 4 : 12} w="100%" direction="column">
        <Flex w="100%" height={isDesktop ? "450px" : "250px"}>
          <FeaturedSlider />
        </Flex>
        <Flex
          mt={2}
          w="100%"
          direction={isDesktop ? "row" : "column"}
          wrap="wrap"
        >
          <Flex flex={3} p={3} direction="column">
            <Box>
              {bannerAds.length > 0 && (
                <BannerAd ad={bannerAds[getRandom(0, bannerAds.length)]} />
              )}
            </Box>
            <H2>Latest News Articles</H2>
            <Flex
              mt={2}
              width="100%"
              wrap="wrap"
              justify={["center", "center", "space-between"]}
              align="center"
            >
              {articles.map(article => (
                <Flex m={2}>
                  <ArticleCard key={article.id} article={article} />
                </Flex>
              ))}
            </Flex>
            <H2 mt={8}>Latest Community Notices</H2>
            <Flex
              mt={2}
              width="100%"
              wrap="wrap"
              justify={["center", "center", "space-between"]}
              align="center"
            >
              {notices.map(notice => (
                <Flex m={2} w="100%">
                  <NoticeCard key={notice.id} notice={notice} />
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Flex
            flex={1.5}
            p={3}
            direction="column"
            height="100%"
            width="100%"
            align="center"
            justify="center"
          >
            <H2>Follow us</H2>
            <Box
              w="100%"
              as="a"
              textAlign="center"
              href="https://www.instagram.com/laudiumtoday"
              target="_blank"
              marginY={4}
              marginX="auto"
            >
              <Img
                fluid={data.instagram?.childImageSharp.fluid}
                style={wrapperStyle}
              />
            </Box>
            <Box
              w="100%"
              as="a"
              href="https://twitter.com/LaudiumToday"
              target="_blank"
              marginY={4}
              marginX="auto"
            >
              <Img
                fluid={data.twitter?.childImageSharp.fluid}
                style={wrapperStyle}
              />
            </Box>
            <Box
              w="100%"
              as="a"
              href="https://facebook.com/laudiumtoday"
              target="_blank"
              marginY={4}
            >
              <Img
                fluid={data.facebook?.childImageSharp.fluid}
                style={wrapperStyle}
              />
            </Box>
            {squareAds.map(squareAd => (
              <Box w="100%" marginY={2}>
                <SquareAd ad={squareAd} />
              </Box>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  )
}
