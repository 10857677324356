import React from "react"
import Slider from "react-slick"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Flex, Box } from "@chakra-ui/core"
import styled from "@emotion/styled"
import { H1, H2 } from "../Typography"
import useWindowSize from "../../hooks/useWindowSize"
import { maxMobileSize } from "../../constants"
import { startCase } from "lodash"

const settings = {
  dots: true,
  infinite: true,
  lazyLoad: "ondemand",
  autoplay: true,
}

function Slide({ fluidImage, title, slug }) {
  const windowSize = useWindowSize()
  const isDesktop = windowSize.width > maxMobileSize
  return (
    <BackgroundImage
      Tag="div"
      fluid={fluidImage}
      backgroundColor={`#040e18`}
      id="test"
      style={{ display: "flex", height: isDesktop ? "450px" : "250px" }}
    >
      <Link to={slug}>
        <Flex
          width="100%"
          height="100%"
          justify="center"
          align="flex-end"
          color="white"
          padding="16px"
          background="linear-gradient(0deg, rgba(22,22,22,0.7847514005602241) 0%, rgba(255,255,255,0) 100%)"
        >
          <Flex>
            {isDesktop ? (
              <H1 marginX={12} textAlign="center" textShadow="1px 0 black">
                {startCase(title)}
              </H1>
            ) : (
              <H2 marginX={2} textAlign="center" textShadow="1px 0 black">
                {startCase(title)}
              </H2>
            )}
          </Flex>
        </Flex>
      </Link>
    </BackgroundImage>
  )
}

const StyledSlide = styled(Slide)`
  && {
    background-position: top;
    background-repeat: none;
    background-size: cover;
  }
`

function FeaturedSlider() {
  const data = useStaticQuery(
    graphql`
      query {
        allArticle(limit: 3, sort: { fields: fields___date, order: DESC }) {
          edges {
            node {
              id
              title
              summary
              featuredImage {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1280) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              fields {
                slug
                timeToRead
                date(formatString: "DD MMMM YYYY")
              }
            }
          }
        }
      }
    `
  )
  const articles = data.allArticle.edges.map(edge => edge.node) || []
  return (
    <Box
      width="90%"
      height="100%"
      id="box"
      margin="auto"
      borderRadius="8px"
      overflow="hidden"
    >
      <Slider {...settings}>
        {articles.map(article => (
          <StyledSlide
            key={article.id}
            fluidImage={article.featuredImage?.childImageSharp.fluid}
            title={article.title}
            slug={article.fields.slug}
          />
        ))}
      </Slider>
    </Box>
  )
}

export default FeaturedSlider
